import { useContext } from "react";
import { ApiContext } from "@/context/api-context";
import { taskHistoryColumns } from "@/views/Services/task_history/task_history_columns";
import {DataTable} from "@/views/table";
import {statuses, taskType} from "@/models/model";

export default function TaskHistoryView() {
  const { analytics } = useContext(ApiContext);

  if (analytics === undefined) {
    return <p>api loading...</p>;
  }

  let taskList = analytics.Tasks.reverse()

  return (
    <>
      <DataTable
          columns={taskHistoryColumns}
          data={taskList}
          filterColumn={"name"}
          facetedFilterColumn={"state"}
          facetedFilterOptions={statuses}
      />
    </>
  );
}
