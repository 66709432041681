import {
  CheckCircledIcon,
  CircleIcon,
  CrossCircledIcon,
  GearIcon,
  MinusCircledIcon,
  StopwatchIcon,
} from "@radix-ui/react-icons";
import {
  AreaChartIcon,
  HardDriveDownloadIcon,
  HardDriveUploadIcon,
} from "lucide-react";

export interface Analytics {
  FetchIndexMapFileStatus: FileStatus;
  FetchAggregateFileStatus: FileStatus;
  FetchSegmentFileStatus: FileStatus;
  FetchSegmentGlobalMapFileStatus: FileStatus;
  //
  IngestAggregateFileStatus: FileStatus;
  IngestSegmentsFileStatus: FileStatus;
  IngestSegmentGlobalMapFileStatus: FileStatus;
  //
  SegmentGlobalMapFileStatus: FileStatus;
  BackupIndexMapFileStatus: FileStatus;
  BackupAggregateFileStatus: FileStatus;
  //
  Tasks: Task[];
  APICalls: HttpCall[];
  CronJobs: CronJob[];
  Listeners: Listener[];
}

export interface FileStatus {
  Files: FileModel[];
}

export interface FileModel {
  state: string;
  name: string;
  reason: string;
  time_taken_to_process: number;
  created_at: string | number;
  started_at: number;
  size: number;
  steps: Step[];
  parent_task_uuid: string;
  progress?: number;
}

export interface Step {
  name: string;
  started_at: string;
  ended_at: string;
  duration: number;
}

export interface HttpCall {
  date: string | number;
  replied_at: string | number;
  path: string;
  arguments: string;
  body?: Object;
  response?: Object;
  is_error: boolean;
}

export interface CronJob {
  id: number;
  name: string;
  frequency: number;
  enabled: boolean;
}

export interface Listener {
  id: number;
  name: string;
  enabled: boolean;
}

export interface Task {
  name: string;
  uuid: string;
  type:
    | "fetch_index_map"
    | "fetch_segments"
    | "fetch_aggregates"
    | "process_aggregates"
    | "process_segments"
    | "backup_aggregates"
    | "backup_segments"
    | "backup_index_map"
    | "run_forecast"
    | "cleanup_aggregates"
    | "listener_queue"
    | "update_segment_map"
    | "update_aggregate_map"
    | "calculate_users"
    | "debug";
  created_at: string | number;
  started_at: string | number;
  ended_at?: string | number;
  state: "unknown" | "pending" | "running" | "done" | "skipped" | "stopping" | "error";
  reason?: string;
  time_taken_to_process: number;
  steps: Step[];
  handler_path: string;
  stoppable: boolean;
}

// utils
export const statuses = [
  {
    value: "unknown",
    label: "Unknown",
    icon: CircleIcon,
  },
  {
    value: "pending",
    label: "Pending",
    icon: CircleIcon,
  },
  {
    value: "processing",
    label: "In Progress",
    icon: StopwatchIcon,
  },
  {
    value: "done",
    label: "Done",
    icon: CheckCircledIcon,
  },
  {
    value: "skipped",
    label: "Skipped",
    icon: MinusCircledIcon,
  },
  {
    value: "error",
    label: "Error",
    icon: CrossCircledIcon,
  },
];

export const taskStatus = [
  {
    value: "unknown",
    label: "Unknown",
    icon: CircleIcon,
  },
  {
    value: "pending",
    label: "Pending",
    icon: CircleIcon,
  },
  {
    value: "running",
    label: "Running",
    icon: StopwatchIcon,
  },
  {
    value: "stopping",
    label: "Stopping",
    icon: StopwatchIcon,
  },
  {
    value: "done",
    label: "Done",
    icon: CheckCircledIcon,
  },
  {
    value: "skipped",
    label: "Skipped",
    icon: MinusCircledIcon,
  },
  {
    value: "error",
    label: "Error",
    icon: CrossCircledIcon,
  },
];

export const taskType = [
  {
    value: "fetch_index_map",
    label: "Fetch Index Map",
    icon: HardDriveDownloadIcon,
  },
  {
    value: "fetch_segments",
    label: "Fetch Segments",
    icon: HardDriveDownloadIcon,
  },
  {
    value: "fetch_aggregates",
    label: "Fetch Aggregates",
    icon: HardDriveDownloadIcon,
  },
  {
    value: "process_aggregates",
    label: "Process Aggregates",
    icon: GearIcon,
  },
  {
    value: "process_segments",
    label: "Process Segments",
    icon: GearIcon,
  },
  {
    value: "backup_aggregates",
    label: "Backup Aggregates",
    icon: HardDriveUploadIcon,
  },
  {
    value: "backup_segments",
    label: "Backup Segments",
    icon: HardDriveUploadIcon,
  },
  {
    value: "backup_index_map",
    label: "Backup Index Map",
    icon: HardDriveUploadIcon,
  },
  {
    value: "run_forecast",
    label: "Run Forecast",
    icon: AreaChartIcon,
  },
  {
    value: "calculate_users",
    label: "Calculate Users",
    icon: AreaChartIcon,
  },
  {
    value: "cleanup_aggregates",
    label: "Cleanup Aggregates",
    icon: CircleIcon,
  },
  {
    value: "listener_queue",
    label: "Listener Queue",
    icon: CircleIcon,
  },
  {
    value: "update_segment_map",
    label: "Update Segment Map",
    icon: GearIcon,
  },
  {
    value: "update_aggregate_map",
    label: "Update Aggregate Map",
    icon: GearIcon,
  },
  {
    value: "debug",
    label: "debug",
    icon: AreaChartIcon,
  },
];

export const apiCallStatus = [
  {
    value: false,
    label: "Success",
    icon: CircleIcon,
  },
  {
    value: true,
    label: "Error",
    icon: CircleIcon,
  },
];
