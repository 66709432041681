import { cn } from "@/lib/utils";
import { Button } from "@/components/button";
import {
  CheckSquareIcon,
  CloudCogIcon,
  HardDriveDownloadIcon,
  HardDriveUploadIcon,
  RssIcon,
} from "lucide-react";
import { Link, useMatchRoute } from "@tanstack/react-router";
import { useContext, useEffect } from "react";
import { fetchAnalytics } from "@/api";
import { ApiContext } from "@/context/api-context";

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {}

interface MyNavLinkProps {
  title: string;
  link: string;
  Icon: any;
  wip?: boolean;
}

function MyNavLink({ title, link, Icon, wip }: MyNavLinkProps) {
  const matchRoute = useMatchRoute();
  const isMatch = matchRoute({ to: link });
  const variantNameButton = isMatch ? "secondary" : "ghost";
  const classNameText = isMatch ? "font-bold" : "";

  return (
    <Link to={link}>
      <Button variant={variantNameButton} className="w-full justify-start">
        <div>
          <Icon className="mr-2 h-4 w-4" />
        </div>
        <p
          style={{
            color: wip || false ? "lightgrey" : "black",
          }}
          className={classNameText}
        >
          {title}
        </p>
      </Button>
    </Link>
  );
}

export function Sidebar({ className }: SidebarProps) {
  const { updateState } = useContext(ApiContext);

  useEffect(() => {
    fetchAnalytics()
      .then((_analytics) => {
        if (_analytics !== undefined) {
          if (Object.values(_analytics as any).length < 1) {
            return;
          }
          updateState({
            analytics: _analytics,
          });
        }
      })
      .catch((err) => {
        console.error("fetching analytics: ", err);
      });

    setInterval(function () {
      fetchAnalytics()
        .then((_analytics) => {
          if (_analytics !== undefined) {
            if (Object.values(_analytics as any).length < 1) {
              return;
            }
            updateState({
              analytics: _analytics,
            });
          }
        })
        .catch((err) => {
          console.error("fetching analytics: ", err);
        });
    }, 6 * 1000);
  }, []);

  return (
    <div className={cn("pb-12", className)}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Fetch
          </h2>
          <div className="space-y-1">
            <MyNavLink
              link="/fetch_index_maps"
              Icon={HardDriveDownloadIcon}
              title="Fetch Index Maps"
            />
            <MyNavLink
              link="/fetch_aggregate_data"
              Icon={HardDriveDownloadIcon}
              title="Fetch Aggregate Data"
            />
            <MyNavLink
              link="/fetch_global_segment_data"
              Icon={HardDriveDownloadIcon}
              title="Fetch Global Segment Data"
            />
            <MyNavLink
              link="/fetch_segment_data"
              Icon={HardDriveDownloadIcon}
              title="Fetch Segment Data"
            />
          </div>
        </div>
        <div className="px-3 py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Process
          </h2>
          <div className="space-y-1">
            <MyNavLink
              link="/ingest_aggregate_data"
              Icon={CloudCogIcon}
              title="Aggregate Data Ingestion"
            />
            <MyNavLink
              link="/ingest_global_segments"
              Icon={CloudCogIcon}
              title="Global Segment Ingestion"
            />
            <MyNavLink
              link="/ingest_single_segments"
              Icon={CloudCogIcon}
              title="Single Segment Ingestion"
            />
          </div>
        </div>
        <div className="px-3 py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Backups
          </h2>
          <div className="space-y-1">
            <MyNavLink
              link="/upload_index_map"
              Icon={HardDriveUploadIcon}
              title="Upload index map"
            />
            <MyNavLink
              link="/upload_aggregate_data"
              Icon={HardDriveUploadIcon}
              title="Upload aggregate data"
            />
            {/*<MyNavLink*/}
            {/*  link="/segment_ingest"*/}
            {/*  Icon={CloudCogIcon}*/}
            {/*  title="Upload segments"*/}
            {/*/>*/}
          </div>
        </div>
        <div className="px-3 py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Forecasts
          </h2>
          <div className="space-y-1">
            {/*<MyNavLink*/}
            {/*    link="/upload_index_map"*/}
            {/*    Icon={HardDriveUploadIcon}*/}
            {/*    title="Upload index map"*/}
            {/*/>*/}
            {/*<MyNavLink*/}
            {/*  link="/segment_ingest"*/}
            {/*  Icon={CloudCogIcon}*/}
            {/*  title="Upload segments"*/}
            {/*/>*/}
          </div>
        </div>
        <div className="px-3 py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Services
          </h2>
          <div className="space-y-1">
            <MyNavLink
              link="/task_history"
              Icon={CheckSquareIcon}
              title="Tasks"
            />
          </div>
          <div className="space-y-1">
            <MyNavLink
                link="/cron_jobs"
                Icon={CheckSquareIcon}
                title="Cron Jobs"
            />
          </div>
          <div className="space-y-1">
            <MyNavLink
                link="/listeners"
                Icon={CheckSquareIcon}
                title="Listeners"
            />
          </div>
          <div className="space-y-1">
            <MyNavLink link="/api_calls" Icon={RssIcon} title="API Calls" />
          </div>
        </div>
        {/*<div className="py-2">*/}
        {/*  <h2 className="relative px-7 text-lg font-semibold tracking-tight">*/}
        {/*    Recent tasks*/}
        {/*  </h2>*/}
        {/*  <ScrollArea className="h-[300px] px-1">*/}
        {/*    <div className="space-y-1 p-2">*/}
        {/*      {["boot", "parse segments"].map((e) => {*/}
        {/*        return <div>{e}</div>;*/}
        {/*      })}*/}
        {/*    </div>*/}
        {/*  </ScrollArea>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
