import React, { useState } from "react";
import { ApiContext, AppState } from "@/context/api-context";
interface Props {
  children: React.ReactNode;
}

/**
 * The main context provider
 */
export const ApiContextProvider: React.FunctionComponent<Props> = (
  props: Props,
): JSX.Element => {
  /**
   * Using react hooks, set the default state
   */
  const [state, setState] = useState({});

  /**
   * Declare the update state method that will handle the state values
   */
  const updateState = (newState: Partial<AppState>) => {
    setState({ ...state, ...newState });
  };

  /**
   * Context wrapper that will provider the state values to all its children nodes
   */
  return (
    <ApiContext.Provider value={{ ...state, updateState }}>
      {props.children}
    </ApiContext.Provider>
  );
};
