import {ColumnDef} from "@tanstack/react-table";
import {CronJob} from "@/models/model";
import {Check, XIcon} from "lucide-react";
import {FORECASTER_DOMAIN} from "@/api";

export const cronJobColumns: ColumnDef<CronJob>[] = [
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "frequency",
        header: "Frequency",
        cell: ({ row }) => {
            if (row.original.frequency === 0) {
                return <p>???</p>;
            }
            let f = row.original.frequency / 60000000000
            let frequencyStr = ""
            if (Math.floor(f / 60) > 0) {
                frequencyStr += `${Math.floor(f/60)} hours `
                f = f % 60
            }
            frequencyStr += `${f} min`
            return (
                <p>
                    {frequencyStr}
                </p>
            );
        },
    },
    {
        accessorKey: "enabled",
        header: "Enabled",
        cell: ({ row }) => {
            return (
                <div>
                    {row.original.enabled ?
                        <div className={"flex justify-center"}>
                            <Check></Check>
                            <button onClick={() => {
                                const url = FORECASTER_DOMAIN + "/api/enable_cron_job?secret=QgvZm4rIFpdIlo98Z&enable=false&id=" + row.original.id;
                                fetch(url);
                            }}>Disable</button>
                        </div> :
                        <div className={"flex justify-center"}>
                            <XIcon></XIcon>
                            <button onClick={() => {
                                const url = FORECASTER_DOMAIN + "/api/enable_cron_job?secret=QgvZm4rIFpdIlo98Z&enable=true&id=" + row.original.id;
                                fetch(url);
                            }}>Enable</button>
                        </div>
                    }
                </div>
            );
        },
    },
]