import React from "react";
import {
  createRouter,
  createRoute,
  createRootRoute,
  Navigate,
} from "@tanstack/react-router";
import AggregateDataIngestView from "@/views/Process/AggregateDataIngest";
import TaskHistoryView from "@/views/Services/task_history/TaskHistory";
import FetchIndexMapView from "@/views/Fetch/FetchIndexMapView";
import ApiCallsView from "@/views/Services/api_calls/ApiCalls";
import UploadIndexMapsView from "@/views/Backup/UploadIndexMaps";
import UploadAggregateDataView from "@/views/Backup/UploadAggregateData";
import FetchAggregateDataView from "@/views/Fetch/FetchAggregateData";
import FetchSegmentDataView from "@/views/Fetch/FetchSegmentData";
import IngestGlobalSegmentsView from "@/views/Process/IngestGlobalSegments";
import FetchGlobalSegmentDataView from "@/views/Fetch/FetchGlobalSegmentData";
import IngestSingleSegmentsView from "@/views/Process/IngestSingleSegments";
import CronJobsView from "@/views/Services/cron_jobs/CronJobs";
import ListenersView from "@/views/Services/listeners/Listener";
import LoginView from "@/views/login/login";
import RootComponent from "@/routes/root";

const rootRoute = createRootRoute({
  component: localStorage.getItem("auth") ? RootComponent : LoginView
});

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/login",
  component: LoginView,
})

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: function Index() {
    return <Navigate to="/task_history" params={{}} />;
  },
});

// fetch
const fetchIndexMapRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/fetch_index_maps",
  component: FetchIndexMapView,
});

const ingestAggregateDataRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/ingest_aggregate_data",
  component: AggregateDataIngestView,
});

const taskHistoryRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/task_history",
  component: TaskHistoryView,
});

const cronJobsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/cron_jobs",
  component: CronJobsView,
});

const listenersRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/listeners",
  component: ListenersView,
})

const apiCallsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/api_calls",
  component: ApiCallsView,
});

const uploadIndexMapsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/upload_index_map",
  component: UploadIndexMapsView,
});

const uploadAggregateDataRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/upload_aggregate_data",
  component: UploadAggregateDataView,
});

const ingestGlobalSegmentRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/ingest_global_segments",
  component: IngestGlobalSegmentsView,
});

const ingestSingleSegmentRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/ingest_single_segments",
  component: IngestSingleSegmentsView,
});

const fetchAggregateDataRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/fetch_aggregate_data",
  component: FetchAggregateDataView,
});

const fetchSegmentDataRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/fetch_segment_data",
  component: FetchSegmentDataView,
});

const fetchGlobalSegmentDataRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/fetch_global_segment_data",
  component: FetchGlobalSegmentDataView,
});

const routeTree = rootRoute.addChildren([
  // login
  loginRoute,
  // root
  indexRoute,
  // ingest
  ingestAggregateDataRoute,
  ingestGlobalSegmentRoute,
  ingestSingleSegmentRoute,
  // backup
  uploadIndexMapsRoute,
  uploadAggregateDataRoute,
  // fetch
  fetchIndexMapRoute,
  fetchAggregateDataRoute,
  fetchSegmentDataRoute,
  // misc
  taskHistoryRoute,
  cronJobsRoute,
  listenersRoute,
  apiCallsRoute,
  fetchGlobalSegmentDataRoute,
]);

export const router = createRouter({ routeTree, defaultPreload: "intent" });
