import { ColumnDef } from "@tanstack/react-table";
import { Step, Task } from "@/models/model";
import { Badge } from "@/components/ui/badge";
import { ArrowUpDown, ChevronDownIcon } from "lucide-react";
import { Button } from "@/components/button";
import humanizeDuration, { HumanizerOptions } from "humanize-duration";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { CheckCircledIcon, StopwatchIcon } from "@radix-ui/react-icons";
import { VscDebugRerun, VscDebugStop } from "react-icons/vsc";
import { Tooltip } from "@mui/material";
import {FORECASTER_DOMAIN} from "@/api";

function formatDurationFromMS(d: number): string {
  return humanizeDuration(Math.round(d / 1000) * 1000, {
    largest: 2,
    units: ["h", "m", "s"],
  } as HumanizerOptions);
}

export const taskHistoryColumns: ColumnDef<Task>[] = [
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (
        (rowA.original.created_at as number) -
        (rowB.original.created_at as number)
      );
    },
    cell: ({ row }) => {
      if (row.original.created_at === 0) {
        return <p>???</p>;
      }
      const d = new Date(row.original.created_at);
      return (
        <p>
          {d.toISOString().slice(0, 10) +
            " " +
            d.getHours().toString().padStart(2, "0") +
            "h" +
            d.getMinutes().toString().padStart(2, "0")}
        </p>
      );
    },
  },
  {
    accessorKey: "started_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Started At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (
        (rowA.original.started_at as number) -
        (rowB.original.started_at as number)
      );
    },
    cell: ({ row }) => {
      if (row.original.started_at === 0) {
        return <p>???</p>;
      }
      const d = new Date(row.original.started_at);
      return (
        <p>
          {d.toISOString().slice(0, 10) +
            " " +
            d.getHours().toString().padStart(2, "0") +
            "h" +
            d.getMinutes().toString().padStart(2, "0")}
        </p>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => {
      const shouldShowCollapsible =
        (row.original.reason || "") !== "" || row.original.steps.length > 0;
      return (
        <Collapsible>
          <CollapsibleTrigger>
            <div className={"flex"}>
              <p style={{ textAlign: "left" }}>{row.getValue("name")}</p>
              {shouldShowCollapsible && <ChevronDownIcon />}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div style={{ paddingTop: "6px" }}>
              {(row.original.steps || []).map((s: Step) => {
                const isStepRunning = s.duration === undefined;
                const formattedDuration = !isStepRunning
                  ? humanizeDuration(Math.round(s.duration / 1000) * 1000)
                  : formatDurationFromMS(Date.now() - Date.parse(s.started_at));
                return (
                  <div style={{ maxWidth: "340px", color: "#787878" }}>
                    <div className="flex place-content-between items-center">
                      <div className="flex items-center space-x-2">
                        {isStepRunning ? (
                          <StopwatchIcon />
                        ) : (
                          <CheckCircledIcon />
                        )}
                        <p>
                          {s.name}: {formattedDuration}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div style={{ maxWidth: "340px", color: "#787878" }}>
                <div className="flex-col place-content-between items-center">
                  {row.original.reason?.split("while").map((s) => {
                    return s.length > 3 ? (
                      <>
                        <p>{s}</p>
                        <br />
                      </>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      );
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Type
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const val = row.original.type || "unknown";
      const getBadge = () => {
        if (val.startsWith("fetch")) {
          return (
            <Badge
              variant="default"
              style={{
                backgroundColor: "#E77200",
              }}
            >
              {val}
            </Badge>
          );
        }
        if (val.startsWith("process") || val.startsWith("update")) {
          return (
            <Badge
              variant="default"
              style={{
                backgroundColor: "#9C51B6",
              }}
            >
              {val}
            </Badge>
          );
        }
        if (val.startsWith("backup")) {
          return (
            <Badge
              variant="default"
              style={{
                backgroundColor: "#5DADEC",
              }}
            >
              {val}
            </Badge>
          );
        }
        if (val.startsWith("run") || val.startsWith("calculate") || val.startsWith("debug")) {
          return (
            <Badge
              variant="default"
              style={{
                backgroundColor: "#C39953",
              }}
            >
              {val}
            </Badge>
          );
        }
        if (val.startsWith("cleanup")) {
          return <Badge
              variant="default"
              style={{
                backgroundColor: "#F88379",
              }}
          >
            {val}
          </Badge>
        }
        if (val.startsWith("listener")) {
          return <Badge
              variant="default"
              style={{
                backgroundColor: "#FFDB58",
              }}
          >
            {val}
          </Badge>
        }
        return <Badge variant="outline">Unknown</Badge>;
      };
      return <div className={"flex justify-center"}>{getBadge()}</div>;
    },
    filterFn: (row, id, value) => {
      if (value === undefined || value.length === 0) {
        return true;
      }
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "state",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const val = row.original.state;
      const getBadge = () => {
        switch (val) {
          case "unknown":
            return <Badge variant="outline">Unknown</Badge>;
          case "pending":
            return <Badge variant="secondary">Pending</Badge>;
          case "running":
          case "stopping":
            return (
              <Badge
                variant="success"
                style={{
                  backgroundColor: "#F7A38E",
                  color: "white",
                  borderWidth: 0,
                }}
              >
                {val}
              </Badge>
            );
          case "done":
            return (
              <Badge
                variant="success"
                style={{
                  backgroundColor: "#229922",
                  color: "white",
                  borderWidth: 0,
                }}
              >
                Done
              </Badge>
            );
          case "skipped":
            return <Badge variant="secondary">Skipped</Badge>;
          case "error":
            return <Badge variant="destructive">Error</Badge>;
        }
        return <Badge variant="outline">Unknown</Badge>;
      };
      return <div className={"flex justify-center"}>{getBadge()}</div>;
    },
    filterFn: (row, id, value) => {
      if (value === undefined || value.length === 0) {
        return true;
      }
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "time_taken_to_process",
    sortingFn: (rowA, rowB, columnId) => {
      return (
        (rowA.original.time_taken_to_process || 0) -
        (rowB.original.time_taken_to_process || 0)
      );
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time to process
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <div className={"flex justify-center"}>
          <p>
            {humanizeDuration(
              Math.round(row.original.time_taken_to_process / 1000) * 1000,
            )}
          </p>
        </div>
      );
    },
  },
  {
    accessorKey: "uuid",
  },
  {
    accessorKey: "",
    header: "Actions",
    cell: ({ row }) => {
      return (
          <div className={"flex justify-center"}>
            {row.original.handler_path !== "" && (row.original.state === "done" || row.original.state === "error") &&
                <Tooltip title="Re-run">
                  <Button onClick={() => {
                      const url = FORECASTER_DOMAIN + row.original.handler_path + "?secret=QgvZm4rIFpdIlo98Z";
                      fetch(url);
                    }
                  } style={{backgroundColor: "#229922"}}>
                      <VscDebugRerun />
                  </Button>
                </Tooltip>
            }
            {row.original.stoppable && row.original.state !== "done" && row.original.state !== "error" && row.original.state !== "stopping" &&
                <Tooltip title="Stop">
                  <Button onClick={() => {
                    const url = FORECASTER_DOMAIN + "/api/stop_tasks?secret=QgvZm4rIFpdIlo98Z&uuid=" + row.original.uuid;
                    fetch(url);
                  }
                  } variant="destructive">
                    <VscDebugStop />
                  </Button>
                </Tooltip>

            }
          </div>
      );
    },
  },
];
