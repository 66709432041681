import {Sidebar} from "@/components/sidebar";
import {Outlet} from "@tanstack/react-router";
import {Button} from "@mui/base";

export default function RootComponent(): JSX.Element {
    return (<>
        <nav className="fixed bottom-[calc(100vh-theme(spacing.16))] left-0 right-0 top-0">
            <div
                className="hidden h-full flex-1 flex-col space-y-8 p-8 md:flex"
                style={{ backgroundColor: "white" }}
            >
                <div className="flex items-center justify-between space-y-2">
                    <h2 className="text-2xl font-bold tracking-tight">
                        Forecaster Web UI
                    </h2>
                    <Button onClick={() => {
                        localStorage.removeItem("auth");
                        window.location.href = "/";
                    }}>Logout</Button>
                </div>
            </div>
        </nav>
        <div className="flex min-h-screen">
            <aside className="sticky top-16 h-[calc(100vh-theme(spacing.16))] w-64 overflow-y-auto">
                <Sidebar />
            </aside>
            <main className="mt-16 flex-1">
                <div className="hidden h-full flex-1 flex-col space-y-8 p-8 md:flex">
                    <div className="flex items-center justify-between space-y-2">
                        <Outlet />
                    </div>
                </div>
            </main>
        </div>
    </>)
}