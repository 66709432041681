import { Fragment, useState } from 'react';
import LoginPage, { Email, Footer, Password, Title, Submit, Logo } from '@react-login-page/page4';
import {CONSOLE_API_DOMAIN} from "@/api";

export function setToken(json: any): any {
    return localStorage.setItem("auth", JSON.stringify(json));
}

export default function LoginView(style={background: null}) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    return (
        <div>
            <LoginPage style={{background: "white", padding: "100px"}}>
                <Title><Fragment>Forecaster Web UI</Fragment></Title>
                <Logo visible={false}></Logo>
                <Email
                    name="username"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Password
                    placeholder="Password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                {error && <Footer>{error}</Footer>}
                <Submit
                    onClick={() => {
                    fetch(CONSOLE_API_DOMAIN + "/v1/oauth/tokens?", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        },
                        body: `grant_type=password&username=${email}&password=${password}&reseller_id=-1`
                    })
                        .then((resp) => {
                            resp.json()
                            .then(data => {
                                if (data.error) {
                                    setError("Incorrect username and password")
                                    return
                                }
                                setToken(data);
                                window.location.href = "/"
                            })
                        })
                }}
                >Login</Submit>
            </LoginPage>
        </div>
    );
}