import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "@/routes/routes";
import { ThemeProvider } from "./components/theme-provider";
import "./index.css";
import { ApiContextProvider } from "@/context/api-context-provider";

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <StrictMode>
    <ThemeProvider>
      <ApiContextProvider>
        <RouterProvider router={router}/>
      </ApiContextProvider>
    </ThemeProvider>
  </StrictMode>,
);

//
// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement,
// );
// root.render(
//   <React.StrictMode>
//     <ThemeProvider>
//       <App />
//     </ThemeProvider>
//   </React.StrictMode>,
// );
