import {useContext} from "react";
import {ApiContext} from "@/context/api-context";
import {DataTable} from "@/views/table";
import {listenerColumns} from "@/views/Services/listeners/listener_columns";

export default function ListenersView() {
    const { analytics } = useContext(ApiContext);

    if (analytics === undefined) {
        return <p>api loading...</p>;
    }

    return (
        <>
            <DataTable
                columns={listenerColumns}
                data={analytics.Listeners}
                filterColumn={"name"}
                facetedFilterColumn={""}
                facetedFilterOptions={[]}
            />
        </>
    );
}