import { useContext } from "react";
import { ApiContext } from "@/context/api-context";
import { apiCallsColumns } from "@/views/Services/api_calls/api_calls_columns";
import {DataTable} from "@/views/table";
import {apiCallStatus} from "@/models/model";

export default function ApiCallsView() {
  const { analytics } = useContext(ApiContext);

  if (analytics === undefined) {
    return <p>api loading...</p>;
  }

  return (
    <>
      <DataTable
          columns={apiCallsColumns}
          data={analytics.APICalls}
          filterColumn={"path"}
          facetedFilterColumn={"is_error"}
          facetedFilterOptions={apiCallStatus}
      />
    </>
  );
}
