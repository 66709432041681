import { ColumnDef } from "@tanstack/react-table";
import { HttpCall } from "@/models/model";
import { Badge } from "@/components/ui/badge";
import { ArrowUpDown, ChevronDownIcon } from "lucide-react";
import { Button } from "@/components/button";
import humanizeDuration from "humanize-duration";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { JSONTree } from "react-json-tree";

const theme = {
  scheme: "chalk",
  base00: "#151515",
  base01: "#202020",
  base02: "#303030",
  base03: "#505050",
  base04: "#b0b0b0",
  base05: "#d0d0d0",
  base06: "#e0e0e0",
  base07: "#f5f5f5",
  base08: "#fb9fb1",
  base09: "#eda987",
  base0A: "#ddb26f",
  base0B: "#acc267",
  base0C: "#12cfc0",
  base0D: "#6fc2ef",
  base0E: "#e1a3ee",
  base0F: "#deaf8f",
};

export const apiCallsColumns: ColumnDef<HttpCall>[] = [
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (rowA.original.date as number) - (rowB.original.date as number);
    },
    cell: ({ row }) => {
      if (row.original.date === 0) {
        return <p>???</p>;
      }
      const d = new Date(row.original.date);
      return (
        <p>
          {d.toISOString().slice(0, 10) +
            " " +
            d.getHours().toString().padStart(2, "0") +
            "h" +
            d.getMinutes().toString().padStart(2, "0")}
        </p>
      );
    },
  },
  {
    accessorKey: "path",
    header: "PATH",
    cell: ({ row }) => {
      const shouldShowExtendedCell =
        (row.original.body || "") !== "" ||
        (row.original.response || "") !== "" ||
        row.original.arguments !== "";
      return (
        <Collapsible>
          <CollapsibleTrigger>
            <div className={"flex"}>
              <p style={{ textAlign: "left" }}>{row.original.path}</p>
              {shouldShowExtendedCell && <ChevronDownIcon />}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div style={{ paddingTop: "6px" }}>
              <div style={{ maxWidth: "340px", color: "#787878" }}>
                <div className="flex-col place-content-between items-center">
                  {row.original.arguments !== "" && (
                    <>
                      <h2>arguments:</h2>
                      <p>{row.original.arguments}</p>
                      <div style={{ paddingBottom: "5px" }}></div>
                    </>
                  )}
                  {(row.original.body || "") !== "" && (
                    <>
                      <h2>body:</h2>
                      <JSONTree data={row.original.body || "{}"} />
                      <div style={{ paddingBottom: "5px" }}></div>
                    </>
                  )}
                  {(row.original.response || "") !== "" && (
                    <>
                      <h2>response:</h2>
                      <JSONTree
                        data={row.original.response || "{}"}
                        theme={theme}
                        invertTheme
                        hideRoot
                      />
                      <div style={{ paddingBottom: "5px" }}></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      );
    },
  },
  {
    accessorKey: "is_error",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const val = row.original.is_error;
      const getBadge = () => {
        if (val) {
          return (
            <Badge
              variant="default"
              style={{
                backgroundColor: "#ef4444",
              }}
            >
              Error
            </Badge>
          );
        }
        return (
          <Badge
            variant="default"
            style={{
              backgroundColor: "#229922",
            }}
          >
            Success
          </Badge>
        );
      };
      return <div className={"flex justify-center"}>{getBadge()}</div>;
    },
    filterFn: (row, id, value) => {
      if (value === undefined || value.length === 0) {
        return true;
      }
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "replied_at",
    sortingFn: (rowA, rowB, columnId) => {
      return (
        ((rowA.original.replied_at as number) -
          (rowA.original.date as number) || 0) -
        ((rowB.original.replied_at as number) -
          (rowB.original.date as number) || 0)
      );
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time to process
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      let val =
        (row.original.replied_at as number) - (row.original.date as number) ||
        0;

      return (
        <div className={"flex justify-center"}>
          <p>{humanizeDuration(val)}</p>
        </div>
      );
    },
  },
];
