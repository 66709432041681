import { Analytics, FileModel } from "./models/model";

let forecasterDomain
let consoleApiDomain
const hostName = window.location.hostname
if (hostName.includes("localhost")) {
  forecasterDomain = "http://" + hostName
  consoleApiDomain = "http://localhost:8080"
} else {
  forecasterDomain = "https://" + hostName
  consoleApiDomain = "https://api.getpublica.com"
}

export const FORECASTER_DOMAIN = forecasterDomain
export const CONSOLE_API_DOMAIN = consoleApiDomain

function enrichFiles(files: FileModel[]) {
  return files.map((f) => {
    if (f.time_taken_to_process === undefined) {
      f.time_taken_to_process = 0;
    }
    if (f.size === undefined) {
      f.size = 0;
    }

    if (f.created_at !== undefined) {
      f.created_at = Date.parse(f.created_at as string);
      if (isNaN(f.created_at)) {
        f.created_at = 0;
      }
    } else {
      f.created_at = 0;
    }

    if (f.steps !== undefined && f.steps.length > 0) {
      f.started_at = Date.parse(f.steps[0].started_at);
      if (isNaN(f.started_at)) {
        f.started_at = 0;
      }
    } else {
      f.started_at = 0;
    }
    return f;
  });
}

const urlGood = `${FORECASTER_DOMAIN}/api/analytics?secret=QgvZm4rIFpdIlo98Z`;

export async function fetchAnalytics(): Promise<Analytics | undefined> {
  try {
    // Make a GET request to the JSON placeholder service
    const response = await fetch(urlGood);

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response body
    let analytics: Analytics = await response.json();

    if (analytics === undefined) {
      return undefined;
    }

    const keys = [
      "FetchIndexMapFileStatus",
      "FetchAggregateFileStatus",
      "FetchSegmentFileStatus",
      "FetchSegmentGlobalMapFileStatus",
      "IngestAggregateFileStatus",
      "IngestSegmentGlobalMapFileStatus",
      "IngestSegmentsFileStatus",
      "SegmentGlobalMapFileStatus",
      "BackupIndexMapFileStatus",
      "BackupAggregateFileStatus",
    ];

    keys.forEach((k) => {
      // @ts-ignore
      if (analytics[k] === undefined) {
        // @ts-ignore
        analytics[k] = {};
      }
      // @ts-ignore
      if (analytics[k].Files === undefined || analytics[k].Files === null) {
        // @ts-ignore
        analytics[k].Files = [];
      }
      // @ts-ignore
      analytics[k].Files = enrichFiles(
        // @ts-ignore
        analytics[k].Files,
      );
    });

    analytics.APICalls = analytics.APICalls.map((a) => {
      if (a.date !== undefined && a.date !== "") {
        a.date = Date.parse(a.date as string);
      } else {
        a.date = 0;
      }

      if (a.replied_at !== undefined && a.replied_at !== "") {
        a.replied_at = Date.parse(a.replied_at as string);
      } else {
        a.replied_at = 0;
      }

      return a;
    });

    analytics.Tasks = (analytics.Tasks || []).map((t) => {
      if (t.steps === undefined || t.steps === null) {
        t.steps = [];
      }
      t.steps = t.steps.map((s) => {
        if (s.duration > 0) {
          s.duration /= 1000000;
        }
        return s;
      });

      if (t.created_at !== undefined && t.created_at !== "") {
        t.created_at = Date.parse(t.created_at as string);
      } else {
        t.created_at = 0;
      }
      if (t.started_at !== undefined && t.started_at !== "") {
        t.started_at = Date.parse(t.started_at as string);
      } else {
        t.started_at = 0;
      }
      if (t.ended_at !== undefined && t.ended_at !== "") {
        t.ended_at = Date.parse(t.ended_at as string);
      } else {
        t.ended_at = 0;
      }

      if (t.started_at && t.ended_at > 0){
        t.time_taken_to_process = t.ended_at - t.started_at;
      } else if (t.state === "running" || t.state === "stopping") {
        if (t.started_at) {
          t.time_taken_to_process = new Date().getTime() - t.started_at;
        }
      } else if (t.state === "pending") {
        t.time_taken_to_process = 0;
      } else {
        t.time_taken_to_process = 0;
      }
      return t;
    });

    // Return the parsed posts
    return analytics;
  } catch (error) {
    // Log any errors to the console
    console.error("Failed to fetch analytics:", error);
    return {} as Analytics;
  }
}
