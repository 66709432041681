import {ColumnDef} from "@tanstack/react-table";
import {Listener} from "@/models/model";
import {Check, XIcon} from "lucide-react";
import {FORECASTER_DOMAIN} from "@/api";

export const listenerColumns: ColumnDef<Listener>[] = [
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "enabled",
        header: "Enabled",
        cell: ({ row }) => {
            return (
                <div>
                    {row.original.enabled ?
                        <div className={"flex justify-center"}>
                            <Check></Check>
                            <button onClick={() => {
                                const url = FORECASTER_DOMAIN + "/api/enable_listener?secret=QgvZm4rIFpdIlo98Z&enable=false&id=" + row.original.id;
                                fetch(url);
                            }}>Disable</button>
                        </div> :
                        <div className={"flex justify-center"}>
                            <XIcon></XIcon>
                            <button onClick={() => {
                                const url = FORECASTER_DOMAIN + "/api/enable_listener?secret=QgvZm4rIFpdIlo98Z&enable=true&id=" + row.original.id;
                                fetch(url);
                            }}>Enable</button>
                        </div>
                    }
                </div>
            );
        },
    },
]