import { ColumnDef } from "@tanstack/react-table";
import { FileModel, Step } from "@/models/model";
import { Badge } from "@/components/ui/badge";
import { ArrowUpDown, ChevronDownIcon } from "lucide-react";
import { Button } from "@/components/button";
import prettyBytes from "pretty-bytes";
import humanizeDuration, { HumanizerOptions } from "humanize-duration";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { CheckCircledIcon, StopwatchIcon } from "@radix-ui/react-icons";
function formatDurationFromMS(d: number): string {
  return humanizeDuration(Math.round(d / 1000) * 1000, {
    largest: 2,
    units: ["h", "m", "s"],
  } as HumanizerOptions).replace(" seconds", "s");
}
export const fileStatusColumns: ColumnDef<FileModel>[] = [
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB, columnId) => {
      return (
        (rowA.original.created_at as number) -
        (rowB.original.created_at as number)
      );
    },
    cell: ({ row }) => {
      if (row.original.created_at === 0) {
        return <p>???</p>;
      }
      const d = new Date(row.original.created_at);
      return (
        <p>
          {d.toISOString().slice(0, 10) +
            " " +
            d.getHours().toString().padStart(2, "0") +
            "h" +
            d.getMinutes().toString().padStart(2, "0")}
        </p>
      );
    },
  },
  {
    accessorKey: "started_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Started At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB, columnId) => {
      return rowA.original.started_at - rowB.original.started_at;
    },
    cell: ({ row }) => {
      if (row.original.started_at === 0) {
        return <p>???</p>;
      }
      const d = new Date(row.original.started_at);
      return (
        <p>
          {d.toISOString().slice(0, 10) +
            " " +
            d.getHours().toString().padStart(2, "0") +
            "h" +
            d.getMinutes().toString().padStart(2, "0")}
        </p>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => {
      let displayChevron =
        (row.original.steps || []).length > 0 ||
        (row.original.reason || "") !== "";
      return (
        <Collapsible>
          <CollapsibleTrigger>
            <div className={"flex"}>
              <p style={{ textAlign: "left" }}>{row.getValue("name")}</p>
              {displayChevron && <ChevronDownIcon />}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div style={{ paddingTop: "6px" }}>
              {(row.original.steps || []).map((s: Step) => {
                const isStepRunning = s.duration === undefined;
                const formattedDuration = !isStepRunning
                  ? humanizeDuration(Math.round(s.duration / 1000000000) * 1000)
                  : formatDurationFromMS(Date.now() - Date.parse(s.started_at));
                return (
                  <div style={{ maxWidth: "340px", color: "#787878" }}>
                    <div className="flex place-content-between items-center">
                      <div className="flex items-center space-x-2">
                        {isStepRunning ? (
                          <StopwatchIcon />
                        ) : (
                          <CheckCircledIcon />
                        )}
                        <p>{s.name}</p>
                      </div>
                      <p>{formattedDuration}</p>
                    </div>
                  </div>
                );
              })}
              {(row.original.reason || "") !== "" && (
                <p style={{ fontWeight: 500 }}>reason: {row.original.reason}</p>
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>
      );
    },
  },
  {
    accessorKey: "state",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const val = row.getValue("state");
      const getBadge = () => {
        switch (val) {
          case "unknown":
            return <Badge variant="outline">Unknown</Badge>;
          case "pending":
            return <Badge variant="secondary">Pending</Badge>;
          case "processing":
            return <Badge variant="outline">In Progress</Badge>;
          case "done":
            return (
              <Badge
                variant="success"
                style={{
                  backgroundColor: "#229922",
                  color: "white",
                  borderWidth: 0,
                }}
              >
                Done
              </Badge>
            );
          case "skipped":
            return <Badge variant="secondary">Skipped</Badge>;
          case "error":
            return <Badge variant="destructive">Error</Badge>;
        }
        return <Badge variant="outline">Unknown</Badge>;
      };
      return <div className={"flex justify-center"}>{getBadge()}</div>;
    },
    filterFn: (row, id, value) => {
      if (value === undefined || value.length === 0) {
        return true;
      }
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "size",
    sortingFn: (rowA, rowB, columnId) => {
      return (rowA.original.size || 0) - (rowB.original.size || 0);
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Size
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      let val = row.getValue("size") as number;
      if (val === undefined) {
        val = 0;
      }
      return (
        <div className={"flex justify-center"}>
          <p>{prettyBytes(val)}</p>
        </div>
      );
    },
  },
  {
    accessorKey: "time_taken_to_process",
    sortingFn: (rowA, rowB, columnId) => {
      return (
        (rowA.original.time_taken_to_process || 0) -
        (rowB.original.time_taken_to_process || 0)
      );
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time to process
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      let val = row.getValue("time_taken_to_process") as number;
      if (val === undefined || val === 0) {
        if (row.original.steps !== undefined && row.original.steps.length > 0) {
          return (
            <div className={"flex justify-center"}>
              <p>
                {formatDurationFromMS(
                  Date.now() - Date.parse(row.original.steps[0].started_at),
                )}
              </p>
            </div>
          );
        }
        return (
          <div className={"flex justify-center"}>
            {row.original.state === "skipped" ? (
              <p>/</p>
            ) : (
              <p>initializing...</p>
            )}
          </div>
        );
      }

      return (
        <div className={"flex justify-center"}>
          <p>{humanizeDuration(Math.round(val / 1000000000) * 1000)}</p>
        </div>
      );
    },
  },
];
