import React from "react";
import { Analytics } from "@/models/model";

export interface AppState {
  analytics?: Analytics;
  updateState: (newState: Partial<AppState>) => void;
}

/**
 * Default application state
 */
const defaultState: AppState = {
  analytics: undefined,
  updateState: (newState?: Partial<AppState>) => {},
};

export const ApiContext = React.createContext<AppState>(defaultState);
