import {useContext} from "react";
import {ApiContext} from "@/context/api-context";
import {DataTable} from "@/views/table";
import {cronJobColumns} from "@/views/Services/cron_jobs/cron_job_columns";

export default function CronJobsView() {
    const { analytics } = useContext(ApiContext);

    if (analytics === undefined) {
        return <p>api loading...</p>;
    }

    return (
        <>
            <DataTable
                columns={cronJobColumns}
                data={analytics.CronJobs}
                filterColumn={"name"}
                facetedFilterColumn={""}
                facetedFilterOptions={[]}
            />
        </>
    );
}