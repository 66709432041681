import { useContext } from "react";
import {DataTable} from "@/views/table";
import { fileStatusColumns } from "@/views/columns";
import { ApiContext } from "@/context/api-context";
import {statuses} from "@/models/model";

export default function AggregateDataIngestView() {
  const { analytics } = useContext(ApiContext);

  if (analytics === undefined) {
    return <p>api loading...</p>;
  }

  return (
    <>
      <DataTable
        columns={fileStatusColumns}
        data={analytics.IngestAggregateFileStatus.Files}
        filterColumn={"name"}
        facetedFilterColumn={"state"}
        facetedFilterOptions={statuses}
      />
    </>
  );
}
